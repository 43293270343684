.container {
  padding: 0 2rem;
}

.header {
  background-color: #f6f6f6;
  box-shadow: 0 2px 4px rgb(0 0 0 / 0.1);
  color: #e2001a;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: sticky;
  text-align: center;
  top: 0;
  z-index: 100;
}

.activeLink {
  color: #e2001a !important;
}

.title {
  align-items: baseline;
  display: flex;
  font-size: 1.7rem;
  gap: 0.5rem;
  margin: 0 0 2rem;
  word-break: break-all;
}

.subTitle {
  font-size: 0.8rem;
  font-weight: normal;
  margin-left: 1rem;
}

.main {
  display: grid;
  gap: 4rem;
  grid-template-columns: 1fr;
  margin: 0 auto;
  max-width: 900px;
  padding: 5rem 2rem;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
}

.card {
  background-color: #fcfcfc;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  color: inherit;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  transition:
    border-color 0.15s ease,
    color 0.15s ease;

  .title {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
  }

  .form {
    background-color: #f1f1f1;
    justify-content: space-between;
  }
}

.cardLabel {
  display: block;
}

.checkbox {
  display: flex;

  label {
    cursor: pointer;
    margin-left: 20px;
  }
}

.componentsContainer {
  background-color: #f6f6f6;
  min-height: 100vh;
}

.mswStatus {
  color: red;
  font-size: 1rem;
  padding-bottom: 1rem;
}

.navigationContainer {
  display: flex;
  justify-content: space-between;
}

.linksContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.loginButtonContainer {
  position: absolute;
  right: 20px;
  top: 20px;
}

.blockWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
  width: minmax(300px, 50%);
}

.blockWrapperTitle {
  margin-bottom: 15px;
}

.popinList {
  margin: var(--rc-d2c-space-16) 0;

  &::marker {
    color: var(--rc-d2c-primary-500);
  }
}

.buttonsGrid,
.buttonsIconGrid {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
}

.buttonsIconGrid {
  grid-template-columns: repeat(2, 1fr);
}

.navLink {
  align-items: center;
  display: flex;
}

.version {
  color: var(--rc-d2c-secondary-500);
  font-size: 0.7rem;
  font-weight: normal;
  padding: 0.2rem;
}

.debug_translations_button {
  font-size: 0.7rem !important;
  font-weight: normal !important;
}
